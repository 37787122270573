// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-almobileattendancetracking-js": () => import("./../src/pages/almobileattendancetracking.js" /* webpackChunkName: "component---src-pages-almobileattendancetracking-js" */),
  "component---src-pages-almobileproductiontracking-js": () => import("./../src/pages/almobileproductiontracking.js" /* webpackChunkName: "component---src-pages-almobileproductiontracking-js" */),
  "component---src-pages-almobiletimetracking-js": () => import("./../src/pages/almobiletimetracking.js" /* webpackChunkName: "component---src-pages-almobiletimetracking-js" */),
  "component---src-pages-blog-press-js": () => import("./../src/pages/blog&press.js" /* webpackChunkName: "component---src-pages-blog-press-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contactus-js": () => import("./../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

